import React, { memo } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";

import { Navbar, Footer, SEO } from "../../components";

import { Masonry } from "../../components/ui";

const Csaladiesgyermek = () => {
  const images = useStaticQuery(graphql`
    query csaladiesgyermekPortfolioQuery {
      allFile(
        filter: {
          sourceInstanceName: { eq: "gallery" }
          relativeDirectory: { eq: "csaladiesgyermek" }
        }
        sort: { fields: childImageSharp___fluid___originalName, order: ASC }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 3200) {
              src
              sizes
              aspectRatio
              presentationHeight
              presentationWidth
              originalName
            }
          }
        }
      }
    }
  `).allFile.nodes.map((item) => ({
    src: item.childImageSharp.fluid.src,
    sizes: item.childImageSharp.fluid.sizes,
    width: item.childImageSharp.fluid.presentationWidth,
    height: item.childImageSharp.fluid.presentationHeight,
  }));

  return (
    <div>
      <SEO
        title="Merkl Kinga - Családi- és gyermekfotózás"
        description="Hagyományos fotózás a számotokra legközelebb álló stílusban. Ötletes, eredeti jó minőségű fényképek a család minden tagjáról. Együtt, külön, kötőjellel. Mindenkiről szokott legalább 1 
        egyéni kép készülni, de természetesen a legfontosabb a családról készült képek lesznek. 
        Többféle háttérrel, beállítással, ruhákban készülnek képek. Készülhetnek képek csak a gyerekekről, de készülhetnek képek csak a felnőttekről gyerekek nélkül. 
        Képek hangulata sokféle lehet: ünnepélyestől és a komolytól kezdve a laza, bohóckódósig. Ezt a csomagot főleg azoknak ajánlom, akik nagyszülőkkel kiegészítve szeretnének családi fotózásra jelentkezni. Hasonló a sima családi fotózáshoz, csak a létszám a különbség."
      />
      <Navbar />
      <div className="min-h-256 flex flex-col items-center justify-center bg-0-500">
        <h1 className="font-bold text-4xl text-white">
          Családi- és gyermekfotózás
        </h1>
        <Link
          to="/portfolio"
          title="További kategóriák"
          className="text-white mt-8 hover:underline"
        >
          További kategóriák
        </Link>
        <Link
          to="/#csomagokesarak"
          title="Árak"
          className="text-white mt-4 hover:underline"
        >
          Árak
        </Link>
      </div>
      <Masonry images={images} />
      <Footer />
    </div>
  );
};

export default memo(Csaladiesgyermek);
